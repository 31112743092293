var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics"},[_c('div',{staticClass:"title"},[_vm._v(" Les statistiques du planning opérationnel ")]),_c('hr'),(_vm.getReportingErrorStatic)?_c('div',{staticClass:"error"},[_c('ul',_vm._l((_vm.getReportingErrorStatic),function(error,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e(),(_vm.getReportingProcessingStatic)?_c('div',{staticClass:"stat-container three-dots-loading"},[_vm._v(" Chargement en cours ")]):_vm._e(),_c('div',{staticClass:"grid-container-statistics"},[(_vm.selectedWeek)?_c('table',{staticClass:"grid"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._l((_vm.getReportingStatFiliales),function(filiale,indexf){return _vm._l((filiale.deposits),function(depot,indexd){return _c('tr',{key:'a' + indexf + indexd,staticClass:"selectedRow",class:{
            'grey-row': !_vm.allOpen && !filiale.checked
          }},[(indexd == 0)?_c('th',{attrs:{"rowspan":filiale.deposits.length}},[_c('b-form-checkbox',{on:{"change":function($event){return _vm.selected(filiale)}},model:{value:(filiale.checked),callback:function ($$v) {_vm.$set(filiale, "checked", $$v)},expression:"filiale.checked"}})],1):_vm._e(),(indexd == 0)?_c('th',{attrs:{"rowspan":filiale.deposits.length}},[_vm._v(" "+_vm._s(filiale.name)+" ")]):_vm._e(),_c('th',{class:{ blue: depot.class, 'grey-light': !depot.class }},[_vm._v(" "+_vm._s(depot.name)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.pose_planifie_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.m_planife_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.pose_realise_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.m_realise_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.planif_pose_percent_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.t_101_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.t_102_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.t_103_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.p_value_w)+" % ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.cls_value_w)+" % ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.ca_value_w)+" € ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.camion_active_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.camion_ca_w)+" € ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.camion_m_w)+" ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.wa_regie_taux_w)+" % ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.wa_regie_ca_w)+" € ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.wa_lead_taux_w)+" % ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.wa_lead_ca_w)+" € ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.wa_terrain_taux_w)+" % ")]),_c('td',{class:{
              'grey-light': depot.class
            }},[_vm._v(" "+_vm._s(depot.totals.wa_terrain_ca_w)+" € ")])])})})],2):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"2px"}}),_c('col',{attrs:{"width":"8px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}}),_c('col',{attrs:{"width":"5px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"blue",attrs:{"colspan":"3"}},[_vm._v("Groupe")]),_c('th',{staticClass:"blue",attrs:{"colspan":"2"}},[_vm._v("Planifié")]),_c('th',{staticClass:"blue",attrs:{"colspan":"9"}},[_vm._v("Posé")]),_c('th',{staticClass:"blue",attrs:{"colspan":"3"}},[_vm._v("Camions")]),_c('th',{staticClass:"blue",attrs:{"colspan":"2"}},[_vm._v("WA régie")]),_c('th',{staticClass:"blue",attrs:{"colspan":"2"}},[_vm._v("WA Lead")]),_c('th',{staticClass:"blue",attrs:{"colspan":"2"}},[_vm._v("WA Terrain")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}}),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("Filiales")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("Dépôt")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("Pose")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("m2")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("Pose")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("m2")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("P/P")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("101")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("102")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("103")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("P")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("CLS")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("CA")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("Activé")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("m2")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("CA")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("Taux")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("CA")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("Taux")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("CA")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("Taux")]),_c('th',{staticClass:"grey-light",attrs:{"colspan":"1"}},[_vm._v("CA")])])
}]

export { render, staticRenderFns }